import {
  Badge,
  Box,
  Button,
  FormLabel,
  HStack,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import pluralize from "pluralize";
import { MouseEventHandler, useEffect, useState } from "react";
import {
  selectAccessToken,
  selectIsShopifyApp,
  selectOrgProperties,
  selectShopifyDomain,
  selectStripeCustomerID,
  selectUserResources,
} from "../../app/appSlice";
import { useAppSelector } from "../../app/store";
import PageLayout from "../../components/PageLayout";
import { PageSection } from "../../components/PageSection";
import { RESOURCES } from "../../constants/user-constants";
import {
  getCustomer,
  getCustomerInvoices,
  getProducts,
  postBillingPortalSession,
} from "../../services/stripe.service";
import { secondaryDark } from "../../theme/colors";
import { CheckoutStep } from "../store-locator/StoreLocatorCheckout";

function BillingScreen() {
  const accessToken = useAppSelector(selectAccessToken);
  const orgProperties = useAppSelector(selectOrgProperties);
  const stripeCustomerID = useAppSelector(selectStripeCustomerID);
  const isShopifyApp = useAppSelector(selectIsShopifyApp);
  const shopifyDomain = useAppSelector(selectShopifyDomain);
  const userResources = useAppSelector(selectUserResources);
  const [isLoadingStripe, setIsLoadingStripeCustomer] = useState(true);
  const [stripeCustomer, setStripeCustomer] = useState<any>(null);
  const [stripeProducts, setStripeProducts] = useState<any[]>([]);
  const [lastInvoice, setLastInvoice] = useState<any>(null);
  const storeLocatorConfigured = [
    "payed",
    "free_trial",
    "failedpayment",
  ].includes(orgProperties?.store_locator?.status || "");

  useEffect(() => {
    if (!!stripeCustomerID) {
      setIsLoadingStripeCustomer(true);
      getCustomer(accessToken)
        .then((customer) => {
          setStripeCustomer(customer);
          return getProducts(accessToken);
        })
        .then((products) => {
          setStripeProducts(products?.data || []);
          return getCustomerInvoices(accessToken);
        })
        .then((invoices) => {
          setLastInvoice(invoices?.[0]);
        })
        .finally(() => setIsLoadingStripeCustomer(false));
    } else {
      setIsLoadingStripeCustomer(false);
    }
  }, [accessToken, stripeCustomerID]);

  const handleStripeBillingPortal: MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();
    postBillingPortalSession(accessToken).then((portalUrl) => {
      if (portalUrl) {
        window.open(portalUrl);
      }
    });
  };

  const getEndSubscription = (subscription: any) => {
    return DateTime.fromMillis(
      subscription.current_period_end * 1000
    ).toLocaleString(DateTime.DATE_FULL);
  };

  const getEndInvoice = (invoice: any) => {
    const collectionMethod = invoice.collection_method;
    return DateTime.fromMillis(
      (collectionMethod === "charge_automatically"
        ? invoice.status_transitions.paid_at
        : invoice.due_date) * 1000
    ).plus({
      year: 1,
    });
  };

  const getEndTrial = () => {
    const endISO = orgProperties?.store_locator?.free_trial_ends;
    if (!endISO) return;
    return DateTime.fromISO(endISO).plus({ days: 1 });
  };

  return (
    <>
      {!userResources.includes(RESOURCES.MANAGE_BILLING) && (
        <PageLayout>
          <PageSection>
            <Box w="100%">
              <Text>
                You don't have permissions to view this page. Please contact
                your admin to view billing.
              </Text>
            </Box>
          </PageSection>
        </PageLayout>
      )}
      {!!userResources.includes(RESOURCES.MANAGE_BILLING) && (
        <PageLayout title="Billing management">
          {!stripeCustomerID && !isShopifyApp && storeLocatorConfigured && (
            <PageSection>
              <Text>
                Your Subscription is managed manually with Dathic. Please email
                us at <a href="mailto: info@dathic.com">info@dathic.com</a> if
                you have any questions.
              </Text>
            </PageSection>
          )}
          {!!stripeCustomer?.subscriptions?.data?.length && (
            <PageSection
              title="Current subscriptions"
              contentDirection="column"
            >
              {!isLoadingStripe && (
                <HStack w="100%" justifyContent="space-between">
                  {stripeCustomer.subscriptions.data.map(
                    (subscription: any) => (
                      <Box textAlign="start">
                        <Text fontWeight="bold">
                          {stripeProducts.find(
                            (p) => p.id === subscription.plan.product
                          )?.name || "Unknown"}
                        </Text>
                        <Text>
                          {Number(
                            subscription.plan.amount / 100
                          ).toLocaleString(undefined, {
                            currency: subscription.currency,
                            style: "currency",
                          })}{" "}
                          every{" "}
                          {pluralize(
                            subscription.plan.interval,
                            subscription.plan.interval_count,
                            subscription.plan.interval_count > 1
                          )}
                        </Text>
                        <Text>
                          {subscription.collection_method === "send_invoice"
                            ? "Next invoice:"
                            : "Your plan renews on"}{" "}
                          {getEndSubscription(subscription)}
                        </Text>
                        <Badge
                          colorScheme={
                            subscription.status === "active"
                              ? "green"
                              : "orange"
                          }
                        >
                          {subscription.status}
                        </Badge>
                      </Box>
                    )
                  )}
                  <Button onClick={handleStripeBillingPortal}>Manage</Button>
                </HStack>
              )}
              {!!isLoadingStripe && <Spinner />}
            </PageSection>
          )}
          {!!lastInvoice && (
            <PageSection
              title="Last Store Locator invoice"
              contentDirection="column"
            >
              {!isLoadingStripe && (
                <HStack w="100%" justifyContent="space-between">
                  <Box textAlign="start">
                    <Text fontWeight="bold">
                      {stripeProducts.find(
                        (p) => p.id === lastInvoice.lines.data[0].price.product
                      )?.name || "Unknown"}
                    </Text>
                    <Text>
                      {Number(lastInvoice.total / 100).toLocaleString(
                        undefined,
                        {
                          currency: lastInvoice.currency,
                          style: "currency",
                        }
                      )}{" "}
                      per year
                    </Text>
                    <Text>
                      Your plan is valid until{" "}
                      {getEndInvoice(lastInvoice).toLocaleString(
                        DateTime.DATE_FULL
                      )}
                    </Text>
                    <Badge
                      colorScheme={
                        lastInvoice.status === "paid" &&
                        getEndInvoice(lastInvoice) >= DateTime.now()
                          ? "green"
                          : "orange"
                      }
                    >
                      {getEndInvoice(lastInvoice) < DateTime.now()
                        ? "Invalid"
                        : lastInvoice.status}
                    </Badge>
                  </Box>
                  <Button onClick={handleStripeBillingPortal}>Manage</Button>
                </HStack>
              )}
              {!!isLoadingStripe && <Spinner />}
            </PageSection>
          )}
          {orgProperties?.store_locator?.status === "free_trial" &&
            orgProperties?.store_locator?.free_trial_ends && (
              <PageSection title="Free trial status" contentDirection="column">
                <HStack w="100%" justifyContent="space-between">
                  <Box textAlign="start">
                    <Text>
                      Your free trial{" "}
                      {getEndTrial() && getEndTrial()!.diffNow("days").days < 0
                        ? "was"
                        : "is"}{" "}
                      valid
                      {getEndTrial()
                        ? ` until ${getEndTrial()!
                            .minus({ days: 1 })
                            .toLocaleString(DateTime.DATE_FULL)}`
                        : ""}
                    </Text>
                    <Badge
                      colorScheme={
                        getEndTrial() && getEndTrial()!.diffNow("days").days < 0
                          ? "orange"
                          : "green"
                      }
                    >
                      {getEndTrial() && getEndTrial()!.diffNow("days").days < 0
                        ? "Expired"
                        : !getEndTrial()
                        ? "Valid"
                        : `Expires ${getEndTrial()!.toRelative({
                            base: DateTime.now().set({
                              hour: 0,
                              minute: 0,
                              second: 0,
                              millisecond: 0,
                            }),
                          })}`}
                    </Badge>
                  </Box>
                  <Button onClick={handleStripeBillingPortal}>Manage</Button>
                </HStack>
              </PageSection>
            )}
          {!storeLocatorConfigured && !isLoadingStripe && (
            <PageSection
              title="Choose a plan"
              contentDirection="column"
              contentProps={{ bg: secondaryDark }}
            >
              <CheckoutStep />
            </PageSection>
          )}
          {!!isShopifyApp && (
            <PageSection title="Manage billing">
              <Box w="100%">
                <Button
                  onClick={(e) =>
                    window.open(
                      `${window.location.protocol}//${shopifyDomain}/admin/settings/billing`
                    )
                  }
                >
                  Go to billing portal
                </Button>
              </Box>
            </PageSection>
          )}
          <PageSection
            title="Billing changes"
            contentDirection="column"
            contentProps={{ alignItems: "flex-start" }}
          >
            <FormLabel fontWeight="bold">
              Change account or billing details
            </FormLabel>
            <Text>
              Need to make changes to your account, update your billing details
              or change your plan? Our team can support you.
            </Text>
            <Button
              variant="link"
              onClick={() =>
                window.open(
                  "https://calendly.com/laura-rocha/30-mins-dathic--locator-support?month=2024-02"
                )
              }
            >
              Book a call
            </Button>
          </PageSection>
        </PageLayout>
      )}
    </>
  );
}
export default BillingScreen;
