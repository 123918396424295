import { Text } from "@chakra-ui/react";
import {
  closeEditor,
  updateEditorValue,
  updateRow,
} from "ka-table/actionCreators";
import { DataType, EditingMode } from "ka-table/enums";
import { ICellEditorProps, ICellTextProps } from "ka-table/props";
import { assign, keys, pick } from "lodash";
import { DateTime } from "luxon";
import pluralize from "pluralize";
import { CustomColumn } from "../components/entity-table/EntityTable";
import LocationSearchInput from "../components/LocationSearchInput";
import type { ProductStore } from "./ProductStore";
import { RecommendationProductStore } from "./RecommendationProductStore";
import { StoreLocatorStoreRecommendation } from "./StoreLocatorStoreRecommendation";

export class Store {
  id!: number;
  secondary_images: string[] = [];
  created_at?: Date;
  name?: string;
  description?: string;
  chain?: string;
  latitude?: number;
  longitude?: number;
  address!: string;
  short_address?: string;
  zipcode?: string;
  city?: string;
  county?: string;
  delivery_entities?: [] = [];
  state?: string;
  country?: string;
  type?: string;
  phone?: string;
  products?: ProductStore[] = [];
  email?: string;
  open_days?: string;
  open_time?: string;
  _geojson?: any;

  address_2?: string;
  url?: string;
  categories?: object;
  yelp_rating?: number;
  score?: number;
  yelp_review_count?: number;
  google_place_id?: string;
  google_place_review_count?: number;
  google_place_rating?: number;
  json_content?: object;
  featured_content?: object | string;
  store_id?: string;
  link_buy?: string;
  has_sales?: boolean = false;
  hot_box?: string;
  image_url?: string;
  image_url_large?: string;
  in_store?: boolean = false;
  organization_id?: number;
  parent_id?: number;
  store_repository_id?: number;
  status?: string;
  category?: string[];
  origin?: string;
  start_date?: string;
  end_date?: string;
  recurrence_rule?: string;
  searched?: any;
  original?: any;
  productRelations?: (
    | RecommendationProductStore
    | StoreLocatorStoreRecommendation
  )[];
  searchedOrg?: any;
  issueSolution?: any;
  issue?: any;

  constructor(candidate: any) {
    assign(this, pick(candidate, keys(this)));
    this.created_at =
      typeof candidate.created_at === "string"
        ? DateTime.fromSQL(candidate.created_at).toJSDate()
        : candidate.created_at;
  }

  buildForUpdate() {
    const { searched, searchedOrg, issueSolution, issue, ...rest } = this;
    const newStore = new Store(JSON.parse(JSON.stringify(rest)));
    delete newStore.delivery_entities;
    delete newStore.products;
    delete newStore.has_sales;
    delete newStore.in_store;
    delete newStore.original;
    newStore.yelp_review_count =
      typeof newStore.yelp_review_count === "number"
        ? Math.ceil(newStore.yelp_review_count)
        : undefined;
    newStore.google_place_review_count =
      typeof newStore.google_place_review_count === "number"
        ? Math.ceil(newStore.google_place_review_count)
        : undefined;
    if (!newStore.parent_id) delete newStore.parent_id;
    if (!newStore.store_id) delete newStore.store_id;
    if (!newStore.secondary_images) newStore.secondary_images = [];
    if (
      !!newStore.featured_content &&
      typeof newStore.featured_content !== "string"
    )
      newStore.featured_content = JSON.stringify(newStore.featured_content);
    return newStore;
  }

  buildForPost() {
    const { searched, searchedOrg, categories, issueSolution, issue, ...rest } =
      this;
    let newStore = JSON.parse(JSON.stringify(rest));
    delete newStore.id;
    newStore = new Store(newStore);
    delete newStore.delivery_entities;
    delete newStore.products;
    delete newStore.has_sales;
    delete newStore.in_store;
    delete newStore.original;
    newStore.yelp_review_count =
      typeof newStore.yelp_review_count === "number"
        ? Math.ceil(newStore.yelp_review_count)
        : undefined;
    newStore.google_place_review_count =
      typeof newStore.google_place_review_count === "number"
        ? Math.ceil(newStore.google_place_review_count)
        : undefined;
    if (!newStore.parent_id) delete newStore.parent_id;
    if (!newStore.store_id) delete newStore.store_id;
    if (!newStore.secondary_images) newStore.secondary_images = [];
    if (
      !!newStore.featured_content &&
      typeof newStore.featured_content !== "string"
    )
      newStore.featured_content = JSON.stringify(newStore.featured_content);
    newStore.origin = newStore.origin || "Manual";
    return newStore;
  }

  buildForSearch() {
    const { searched, searchedOrg, issueSolution, issue, ...rest } = this;
    let newStore = JSON.parse(JSON.stringify(rest));
    return {
      name: newStore.name,
      description: newStore.description,
      chain: newStore.chain,
      latitude: newStore.latitude,
      longitude: newStore.longitude,
      address: newStore.address,
      short_address: newStore.short_address,
      zipcode: newStore.zipcode,
      city: newStore.city,
      county: newStore.county,
      state: newStore.state,
      country: newStore.country,
      type: newStore.type,
      phone: newStore.phone,
      email: newStore.email,
      link_buy: newStore.link_buy,
      image_url: newStore.image_url,
      secondary_images: newStore.secondary_images,
      image_url_large: newStore.image_url_large,
      open_days: newStore.open_days,
      open_time: newStore.open_time,
      address_2: newStore.address_2,
      url: newStore.url,
      categories: newStore.categories,
      yelp_rating: newStore.yelp_rating,
      yelp_review_count: newStore.yelp_review_count,
      json_content: newStore.json_content,
      google_place_id: newStore.google_place_id,
    };
  }

  getReviewCount() {
    return Object.entries(this).reduce(
      (sum, [key, value]) =>
        key.includes("review_count") ? sum + (value ?? 0) : sum,
      0
    );
  }

  getAvgRating() {
    const numRatings = Object.entries(this).reduce(
      (sum, [key, value]) =>
        key.includes("review_count") && typeof value === "number"
          ? sum + value
          : sum,
      0
    );
    const ratingSum = Object.entries(this).reduce((sum, [key, rating]) => {
      if (key.includes("rating")) {
        const source = key.split("_rating")[0];
        const count = this?.[`${source}_review_count` as keyof Store];
        return typeof rating === "number"
          ? sum + (rating ?? 0) * (count ?? 0)
          : sum;
      }
      return sum;
    }, 0);
    return numRatings > 0 ? ratingSum / numRatings : 0;
  }

  static getSchema = (
    statesLabel = "State",
    zipcodeLabel = "Zipcode",
    storeTypes?: (string | undefined)[]
  ) => [
    { key: "address", label: "address", type: "string", isRequired: true },
    {
      key: "short_address",
      label: "short_address",
      type: "string",
    },
    { key: "name", label: "name", type: "string" },
    { key: "description", label: "description", type: "string" },
    {
      key: "state",
      label: pluralize(statesLabel, 1),
      type: "string",
    },
    { key: "city", label: "city", type: "string" },
    {
      key: "zipcode",
      label: pluralize(zipcodeLabel, 1),
      type: "string",
    },
    { key: "store_id", label: "external store_id", type: "string" },
    { key: "email", label: "email", type: "string" },
    { key: "phone", label: "phone", type: "string" },
    { key: "link_buy", label: "buy url", type: "string" },
    { key: "image_url", label: "image url", type: "string" },
    { key: "chain", label: "chain", type: "string" },
    { key: "type", label: "type", type: "string" },
    { key: "latitude", label: "lat", type: "number" },
    { key: "longitude", label: "lng", type: "number" },
    ...(storeTypes?.includes("popup_store")
      ? [
          { key: "recurrence_rule", label: "Recurrence", type: "string" },
          {
            key: "start_date",
            label: "Start Date",
            type: "string",
            isRequired: true,
          },
          {
            key: "endDate",
            label: "End Date",
            type: "string",
            isRequired: true,
          },
        ]
      : []),
  ];

  static AddressAutocomplete(
    props: ICellEditorProps & { isEditing?: boolean; data?: Store[] }
  ) {
    return props.isEditing ? (
      <LocationSearchInput
        style={{ width: "100%" }}
        onGeocode={async (res: any) => {
          const state: string =
            res.address_components.find((component: any) =>
              component.types.includes("administrative_area_level_1")
            )?.short_name ?? "";
          const city: string =
            res.address_components.find((component: any) =>
              component.types.includes("locality")
            )?.long_name ?? "";
          const country: string =
            res.address_components.find((component: any) =>
              component.types.includes("country")
            )?.short_name ?? "";
          const zipcode: string = (
            res.address_components.find((component: any) =>
              component.types.includes("postal_code")
            )?.long_name ?? ""
          )
            .replaceAll(" ", "")
            .slice(0, country === "CA" ? 3 : undefined);
          const address: string = res.formatted_address ?? "";
          const latitude: number = res.geometry.location.lat() ?? 0;
          const longitude: number = res.geometry.location.lng() ?? 0;

          props.dispatch(
            updateRow({
              ...props.rowData,
              state,
              city,
              zipcode,
              address,
              latitude,
              longitude,
            })
          );
          props.dispatch(closeEditor(props.rowKeyValue, props.column.key));
        }}
        inputProps={{
          defaultValue: "",
          // onBlur: () => {
          //   props.dispatch(
          //     updateCellValue(
          //       props.rowKeyValue,
          //       props.column.key,
          //       props.editorValue
          //     )
          //   );
          //   props.dispatch(closeEditor(props.rowKeyValue, props.column.key));
          // },
        }}
        value={props.editorValue}
        onChange={(newSearchText: string) => {
          props.dispatch(
            updateEditorValue(
              props.rowKeyValue,
              props.column.key,
              newSearchText
            )
          );
        }}
        onTimeout={undefined}
      />
    ) : (
      <Text
        {...props}
        noOfLines={3}
        {...(props.editingMode === EditingMode.Cell
          ? { color: "gray", fontStyle: "italic" }
          : {})}
      >
        {(props as ICellTextProps).format?.(props) ||
          props.value ||
          (props.column as CustomColumn<Store>).default ||
          (props.editingMode === EditingMode.Cell ? "Click to edit" : "")}
      </Text>
    );
  }

  static getSchemaForTable = (...params: any[]) =>
    Store.getSchema(...params).map((item) => ({
      key: item.key,
      title: item.label,
      isRequired: item.isRequired,
      dataType: item.type as DataType,
      Render:
        item.key === "address"
          ? (p: any) => {
              return <Store.AddressAutocomplete {...(p || {})} />;
            }
          : undefined,
    }));
}
