import { assign, keys, pick } from "lodash";

export class Pagination<T> {
  current_page: number = 1;
  has_next: boolean = false;
  has_prev: boolean = false;
  items: T[] = [];
  next_page?: number;
  pages: number = 1;
  prev_page?: number;
  total: number = 0;

  constructor(candidate: any) {
    assign(this, pick(candidate, keys(this)));
  }
}
