import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  IconButton,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import pluralize from "pluralize";
import React, { useCallback, useContext, useMemo } from "react";
import { IoPricetags } from "react-icons/all";
import { FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { selectAccessToken, selectOrgProperties } from "../../app/appSlice";
import { useAppSelector } from "../../app/store";
import MessageModalContext from "../../contexts/MessageModalContext";
import { Store } from "../../domain/Store";
import { putStoresBatch } from "../../services/api.service";
import FeaturedContentList, {
  FeaturedContent,
  FeaturedContentCard,
} from "../store-locator/FeaturedContentList";

type Props = {
  stores?: Store[];
  onFinish?: (newStores: Store[]) => void;
  isDisabled?: boolean;
};

type FormProps = {
  dialogState: {
    stores: Store[];
    featuredContent?: FeaturedContent[];
    othersToDelete: FeaturedContent[];
    storeLabel: string;
  };
  contentNotInAllStores: (content: FeaturedContent[]) => FeaturedContent[];
  setDialogState: React.Dispatch<any>;
};

function getFeaturedContent(
  stores: Store[],
  featuredContent: FeaturedContent[]
) {
  const uniqueContent = Array.from(new Set(featuredContent.map((f) => f.name)))
    .map((name) => featuredContent.find((f) => f.name === name))
    .filter((f) => !!f)
    .map((f) => f as FeaturedContent);
  return uniqueContent.filter((f) =>
    stores.every((s) =>
      (
        (s?.featured_content &&
          (typeof s.featured_content === "string"
            ? JSON.parse(s.featured_content)
            : s.featured_content)) ||
        []
      ).find((sf: FeaturedContent) => sf.name === f.name)
    )
  );
}

const BatchFeaturedContentForm = ({
  dialogState,
  setDialogState,
  contentNotInAllStores,
}: FormProps) => {
  const { featuredContent, othersToDelete, stores, storeLabel } = dialogState;

  return (
    <VStack>
      <FeaturedContentList
        content={featuredContent}
        setContent={(newFeaturedContent) =>
          setDialogState((oldState: any) => ({
            ...(oldState || {}),
            featuredContent:
              typeof newFeaturedContent === "function"
                ? newFeaturedContent(oldState?.featuredContent || [])
                : newFeaturedContent,
          }))
        }
        w="100%"
        direction="column"
      />
      {!!contentNotInAllStores(featuredContent || []).length && (
        <VStack w="100%" p={2} overflow="auto">
          {contentNotInAllStores(featuredContent || []).map((fc) => (
            <Box rounded="lg" zIndex={1} w="300px">
              <HStack w="100%" p={1}>
                <Spacer />
                {!othersToDelete.find((f) => f.name === fc.name) && (
                  <IconButton
                    aria-label={"Remove featured content"}
                    icon={<FaTrash />}
                    size="xs"
                    variant="link"
                    m={1}
                    onClick={() =>
                      setDialogState((oldState: any) => ({
                        ...(oldState || {}),
                        othersToDelete: [
                          ...(oldState?.othersToDelete || []),
                          fc,
                        ],
                      }))
                    }
                  />
                )}
              </HStack>
              <FeaturedContentCard content={fc} />
              <HStack w="100%" p={1}>
                {!othersToDelete.find((f) => f.name === fc.name) && (
                  <Text color="tomato" fontSize="sm">
                    Only in{" "}
                    {pluralize(
                      storeLabel,
                      stores.filter(
                        (s) =>
                          !!(
                            (s?.featured_content &&
                              (typeof s.featured_content === "string"
                                ? JSON.parse(s.featured_content)
                                : s.featured_content)) ||
                            []
                          ).find((f: FeaturedContent) => f.name === fc.name)
                      ).length,
                      true
                    )}
                  </Text>
                )}
                {!!othersToDelete.find((f) => f.name === fc.name) && (
                  <Text fontSize="sm">
                    Will be removed from{" "}
                    {pluralize(
                      storeLabel,
                      stores.filter(
                        (s) =>
                          !!(
                            (s?.featured_content &&
                              (typeof s.featured_content === "string"
                                ? JSON.parse(s.featured_content)
                                : s.featured_content)) ||
                            []
                          ).find((f: FeaturedContent) => f.name === fc.name)
                      ).length,
                      true
                    )}{" "}
                    after saving
                  </Text>
                )}
                <Spacer />
                <VStack alignItems="flex-end">
                  <Button
                    leftIcon={<AddIcon />}
                    variant="link"
                    onClick={() => {
                      setDialogState((oldState: any) => ({
                        ...(oldState || {}),
                        featuredContent: [
                          ...(oldState?.featuredContent || []),
                          fc,
                        ],
                      }));
                      setDialogState((oldState: any) => ({
                        ...(oldState || {}),
                        othersToDelete: (oldState?.othersToDelete || []).filter(
                          (f: any) => f.name !== fc.name
                        ),
                      }));
                    }}
                  >
                    Add to {pluralize(storeLabel, stores?.length, true)}
                  </Button>
                </VStack>
              </HStack>
            </Box>
          ))}
        </VStack>
      )}
    </VStack>
  );
};

const ManageFeaturedContentButton = ({
  stores = [],
  onFinish,
  isDisabled,
}: Props) => {
  const messageModalContext = useContext(MessageModalContext);
  const orgProperties = useAppSelector(selectOrgProperties);
  const accessToken = useAppSelector(selectAccessToken);
  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";
  const allStoresContent = useMemo(
    () =>
      stores.flatMap<FeaturedContent>(
        (store) =>
          (store?.featured_content &&
            (typeof store.featured_content === "string"
              ? JSON.parse(store.featured_content)
              : store.featured_content)) ||
          []
      ),
    [stores]
  );

  const originalContent = useMemo(
    () => getFeaturedContent(stores, allStoresContent),
    [allStoresContent, stores]
  );

  const contentNotInAllStores = useCallback(
    (featuredContent?: any[]) => {
      return allStoresContent.reduce<FeaturedContent[]>((acc, fc) => {
        return [
          ...acc,
          ...(featuredContent?.find((f) => f.name === fc.name) ||
          originalContent.find((f) => f.name === fc.name)
            ? []
            : acc.find((f) => f.name === fc.name)
            ? []
            : [fc]),
        ];
      }, []);
    },
    [allStoresContent, originalContent]
  );

  const handleSave = async (
    i: number,
    dialogState: any,
    setDialogState: React.Dispatch<any>
  ) => {
    const { stores, featuredContent, othersToDelete } = dialogState || {};

    const deletedContent = originalContent.filter(
      (f) => !featuredContent.find((fc: any) => fc.name === f.name)
    );
    setDialogState((oldState: any) => ({
      ...(oldState || {}),
      status: `Updating ${pluralize(storeLabel)}`,
      isLoading: true,
    }));
    const res = await putStoresBatch(
      accessToken,
      stores.map((s: any) => {
        const toUpdate = new Store({
          id: s.id,
          address: s.address,
          secondary_images: s.secondary_images,
        });
        toUpdate.featured_content = [
          ...featuredContent,
          ...(
            (s?.featured_content &&
              (typeof s.featured_content === "string"
                ? JSON.parse(s.featured_content)
                : s.featured_content)) ||
            []
          ).filter(
            (fc: FeaturedContent) =>
              !featuredContent.find((f: any) => f.name === fc.name) &&
              !deletedContent.find((dc: any) => dc.name === fc.name) &&
              !othersToDelete.find((dc: any) => dc.name === fc.name)
          ),
        ];
        return toUpdate.buildForUpdate();
      }),
      "featured_content"
    );
    if (res?.result === "ok") {
      setDialogState((oldState: any) => ({
        ...(oldState || {}),
        status: "",
        isLoading: false,
      }));
      toast.success(
        `Featured content for ${pluralize(
          storeLabel,
          stores.length,
          true
        )} updated`
      );
      const newStores = stores.map(
        (store: any) =>
          new Store({
            ...store,
            featured_content: [
              ...featuredContent,
              ...(
                (store?.featured_content &&
                  (typeof store.featured_content === "string"
                    ? JSON.parse(store.featured_content)
                    : store.featured_content)) ||
                []
              ).filter(
                (fc: FeaturedContent) =>
                  !featuredContent.find((f: any) => f.name === fc.name) &&
                  !deletedContent.find((dc: any) => dc.name === fc.name) &&
                  !othersToDelete.find((dc: any) => dc.name === fc.name)
              ),
            ],
          })
      );
      onFinish?.(newStores);
    }
    messageModalContext.dismissModal(i);
  };

  return (
    <>
      <Button
        leftIcon={<IoPricetags />}
        variant="link"
        aria-label={"Batch create featured content button"}
        isDisabled={!stores.length || isDisabled}
        onClick={() => {
          messageModalContext.showModal({
            title: `Manage featured content for ${pluralize(
              storeLabel,
              stores?.length,
              true
            )}`,
            actions: [
              {
                label: "Remove all",
                props: { variant: "link" },
                preventDismiss: true,
                callback: (index, dialogState, setDialogState) => {
                  setDialogState((oldState: any) => ({
                    ...(oldState || {}),
                    othersToDelete: [
                      ...(oldState?.othersToDelete || {}),
                      ...contentNotInAllStores(
                        oldState?.featuredContent
                      ).filter(
                        (f) =>
                          !oldState?.othersToDelete.find(
                            (fc: any) => fc.name === f.name
                          )
                      ),
                    ],
                    featuredContent: [],
                  }));
                },
              },
              { label: "spacer", Render: () => <Spacer /> },
              {
                label: "spacer",
                Render: (props) =>
                  !!props?.status ? (
                    <Text color="gray" fontSize="sm">
                      {props.status}...
                    </Text>
                  ) : (
                    <></>
                  ),
              },
              { label: "spacer", Render: () => <Spacer /> },
              {
                label: "Cancel",
                isLeastDestructive: true,
              },
              {
                label: "Save",
                props: { colorScheme: "blue", ml: 3 },
                preventDismiss: true,
                callback: (i, dialogState, setDialogState) =>
                  handleSave(i, dialogState, setDialogState),
              },
            ],
            initialDialogState: {
              featuredContent: getFeaturedContent(stores, allStoresContent),
              stores: stores,
              storeLabel: storeLabel,
              othersToDelete: [],
            },
            message: (dialogState, setDialogState) => (
              <BatchFeaturedContentForm
                dialogState={dialogState}
                setDialogState={setDialogState}
                contentNotInAllStores={contentNotInAllStores}
              />
            ),
          });
        }}
      >
        Featured content
      </Button>
    </>
  );
};

export default ManageFeaturedContentButton;
